
import MetaMaskSDK from "@metamask/sdk";
const options = {
  injectProvider: false,
  communicationLayerPreference: "webrtc",
  enableDebug: true
};
const MMSDK = new MetaMaskSDK(options);

const ethereum = MMSDK.getProvider(); // You can also access via window.ethereum

const connect = () => {
  ethereum
    .request({
      method: "eth_requestAccounts",
      params: []
    })
    .then((res) => console.log("request accounts", res))
    .catch((e) => console.log("request accounts ERR", e));
};

export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
      <button onClick={connect}>asd</button>
    </div>
  );
}